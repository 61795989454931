import React, { memo, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { logEvent } from 'firebase/analytics';

import Context from '../../../components/Context';
import EnvVars from '../../../services/EnvVars';
import PaymentMethodContent from '../../../components/payment-method/PaymentMethodContent';
import StripeApi from '../../../api/StripeApi';
import { PaymentResultCompleted } from '../../../components/payment-result/PaymentResultCompleted';
import { TPaymentType } from '../../../models/UserMetadata';
import { analytics } from '../../../services/FirebaseService';
import { getQueryParam } from './getQueryParam';
import { stringToCents } from '../../../utils/Money';
import { useFailedPaymentEffect } from './useFailedPaymentEffect';
import { SentryIO } from '../../../services/SentryIO';
import { usePaymentMethods } from '../../../hooks';
import { RequiredPaymentIntentFields } from '../../../services/stripe/models';
import { Customer } from '../../../types/customerTypes';
import { useCustomerData } from './useCustomerData';

type Props = { location: any };

const PAYMENT_INTENT_CLIENT_SECRET_PARAM = 'payment_intent_client_secret';
const PAYMENT_INTENT_PARAM = 'payment_intent';
const PAYMENT_INTENT_ID_KEY = '@PAYMENT_INTENT_ID';

const PAYMENT_INTENT_STATUS_PROCESSING = 'processing';
const PAYMENT_INTENT_STATUS_SUCCEEDED = 'succeeded';
const PAYMENT_INTENT_STATUS_REUIRES_PAYMENT_METHOD = 'requires_payment_method';

const stripe = Stripe(EnvVars.STRIPE_PK, {
  betas: ['pay_by_bank_beta_1'],
});

export const PaymentMethodScreen = memo(({ location }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const { paymentRequest } = useContext(Context);
  const history = useHistory();
  const [screenKey, setScreenKey] = useState(0);

  const [originalTabStatus, setOriginalTabStatus] = useState<
    'success' | 'failure' | null
  >(null);

  const [paymentIntentStatus, setPaymentIntentStatus] = useState<
    string | undefined
  >();

  const queryPaymentIntentId =
    getQueryParam(PAYMENT_INTENT_PARAM, history.location.search) || '';

  const clientSecret = getQueryParam(
    PAYMENT_INTENT_CLIENT_SECRET_PARAM,
    history.location.search,
  );

  const paymentParams = location.state?.userId
    ? location.state
    : JSON.parse(localStorage.getItem(queryPaymentIntentId) || '{}');

  const {
    userId,
    organisation,
    transaction,
    systemSettings,
    invoiceId,
    paymentType,
    isAmountPrefilled,
    nabyCustomerId,
  } = paymentParams;
  const sessionPaymentIntentId = sessionStorage.getItem(PAYMENT_INTENT_ID_KEY);

  const maxAmount = 1000000;
  const { stripeBankEnabled, stripeCardEnabled } = usePaymentMethods(
    organisation,
    stringToCents(transaction.amount) > maxAmount,
  );

  useFailedPaymentEffect({
    clientSecret,
    history,
    organisation,
    queryPaymentIntentId,
    stripe: stripe,
    transaction,
    userId,
    invoiceId,
    paymentType,
    isAmountPrefilled,
    nabyCustomerId,
  });

  const { fetchingCustomerData, nabyCustomerData } = useCustomerData({
    nabyCustomerId,
    location,
  });

  // Log event
  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Pay - select payment',
      firebase_screen_class: 'Pay - select payment',
    });
  }, []);

  // Clear session storage once the original tab is opened
  useEffect(() => {
    if (sessionPaymentIntentId && !queryPaymentIntentId) {
      sessionStorage.clear();
      setScreenKey((prevKey) => prevKey + 1);
    }
  }, [queryPaymentIntentId, sessionPaymentIntentId]);

  // Track payment intent status
  useEffect(() => {
    if (clientSecret) {
      const getPaymentIntentStatus = async () => {
        try {
          setLoading(true);
          const { paymentIntent } = await stripe.retrievePaymentIntent(
            clientSecret,
          );

          if (paymentIntent?.status) {
            setPaymentIntentStatus(paymentIntent?.status);
          }
        } finally {
          setLoading(false);
        }
      };

      getPaymentIntentStatus();
    }
  }, [clientSecret]);

  // Show success text on the original tab
  useEffect(() => {
    if (
      paymentIntentStatus &&
      queryPaymentIntentId === sessionPaymentIntentId &&
      [
        paymentIntentStatus === PAYMENT_INTENT_STATUS_SUCCEEDED,
        paymentIntentStatus === PAYMENT_INTENT_STATUS_PROCESSING,
      ].includes(true)
    ) {
      setOriginalTabStatus('success');
    } else if (
      paymentIntentStatus &&
      paymentIntentStatus === PAYMENT_INTENT_STATUS_REUIRES_PAYMENT_METHOD &&
      queryPaymentIntentId === sessionPaymentIntentId
    ) {
      setOriginalTabStatus('failure');
    }
  }, [paymentIntentStatus]);

  // Navigate to success screen
  useEffect(() => {
    if (
      [
        paymentIntentStatus === PAYMENT_INTENT_STATUS_SUCCEEDED,
        paymentIntentStatus === PAYMENT_INTENT_STATUS_PROCESSING,
      ].includes(true) &&
      !sessionPaymentIntentId
    ) {
      history.push('/pay/success', {
        organisation,
        transaction: {
          ...transaction,
          id: queryPaymentIntentId,
          clientSecret,
        },
      });
    }
  }, [paymentIntentStatus]);

  // Navigate to failure screen
  useEffect(() => {
    if (
      paymentIntentStatus === PAYMENT_INTENT_STATUS_REUIRES_PAYMENT_METHOD &&
      !sessionPaymentIntentId
    ) {
      console.log('PaymentMethodScreen / Nav to the failure screen', {
        error: 'Something went wrong',
        userId,
        organisation,
        transaction: {
          ...transaction,
          id: queryPaymentIntentId,
          clientSecret,
        },
        invoiceId,
        paymentType,
        isAmountPrefilled,
        nabyCustomerId,
      });

      history.push('/pay/failure', {
        error: 'Something went wrong',
        userId,
        organisation,
        transaction: {
          ...transaction,
          id: queryPaymentIntentId,
          clientSecret,
        },
        invoiceId,
        paymentType,
        isAmountPrefilled,
        nabyCustomerId,
      });
    }
  }, [paymentIntentStatus, invoiceId, paymentType, isAmountPrefilled]);

  useEffect(() => {
    if (!Object.keys(paymentParams).length) {
      history.goBack();
    }
  }, [paymentParams]);

  const handleBankTransferClick = async () => {
    setLoading(true);
    const amount = stringToCents(transaction.amount);

    try {
      const createPaymentIntentParams: RequiredPaymentIntentFields = {
        userId,
        organisationId: organisation?.id || '',
        amount,
        currency: 'gbp',
        date: transaction.date,
        refNum: transaction.refNum,
        isOpenBanking: true,
        isAmountPrefilled,
        paymentType: paymentType || undefined,
        nabyCustomerId: paymentParams.nabyCustomerId,
      };

      if (invoiceId) {
        createPaymentIntentParams.invoiceId = invoiceId;
      }

      const {
        data: { id, clientSecret },
      } = await StripeApi.createPaymentIntent(createPaymentIntentParams);

      localStorage.setItem(
        id,
        JSON.stringify({
          userId,
          organisation,
          transaction,
          paymentType,
          invoiceId,
          isAmountPrefilled,
          nabyCustomerId,
        }),
      );

      sessionStorage.setItem(PAYMENT_INTENT_ID_KEY, id);

      // @ts-expect-error
      const { error } = await stripe.confirmPayByBankPayment(clientSecret, {
        return_url: window.location.href,
      });
    } catch (error) {
      alert(error);
      SentryIO.captureException(error, {
        extra: {
          prompt: 'Payment Method screen. Payment by bank failed',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = () => {
    history.push('/pay/confirm', {
      userId,
      organisation,
      transaction,
      paymentType,
      systemSettings,
      invoiceId,
      nabyCustomerId,
      isAmountPrefilled,
      nabyCustomerData,
    });
  };

  console.log('PaymentMethodScreen / User ID', userId);
  console.log('PaymentMethodScreen / Invoice ID', invoiceId);
  console.log('PaymentMethodScreen / Payment type', paymentType);
  console.log('PaymentMethodScreen / Amout prefilled', isAmountPrefilled);
  console.log('PaymentMethodScreen / Naby customer ID', nabyCustomerId);
  console.log('PaymentMethodScreen / Naby customer data', nabyCustomerData);

  if (!Object.keys(paymentParams).length) return null;

  if (originalTabStatus === 'success') {
    return <PaymentResultCompleted />;
  }
  return (
    <PaymentMethodContent
      bankTransferClick={handleBankTransferClick}
      cardClick={handleCardClick}
      isAmountPrefilled={isAmountPrefilled}
      isLoading={isLoading || fetchingCustomerData}
      key={screenKey}
      paymentFields={paymentParams}
      paymentRequestParam={paymentRequest}
      paymentType={paymentType}
      invoiceId={invoiceId}
      nabyCustomerData={nabyCustomerData}
      nabyCustomerId={nabyCustomerId}
    />
  );
});

export default PaymentMethodScreen;
