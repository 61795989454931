import { Customer } from '../../../types/customerTypes';
import { StorageKeys } from '../../../types/storageKeys';

export const get3DSecureRedirectData = (): {
  organisation: object;
  transaction: object;
  userId: string;
  systemSettings: object;
  invoiceId?: string;
  paymentType?: string;
  isAmountPrefilled?: boolean;
  nabyCustomerId?: string;
  nabyCustomerData?: Customer;
} | null => {
  const stringified3DSecureRedirectData = sessionStorage.getItem(
    StorageKeys.ThreeDSecureRedirectData,
  );

  return stringified3DSecureRedirectData
    ? JSON.parse(stringified3DSecureRedirectData)
    : null;
};
