import { FC, memo, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
import { logEvent } from 'firebase/analytics';

import ProvidersContent from '../../components/providers/ProvidersContent';
import { stringToCents } from '../../utils/Money';
import Context, { ActionTypes } from '../../components/Context';
import { analytics } from '../../services/FirebaseService';
import { SentryIO } from '../../services/SentryIO';

type Props = { location: any };

const ProvidersScreen: FC<Props> = memo(({ location }: Props) => {
  const history = useHistory();
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const { dispatch } = useContext(Context);

  const userId = location.state.userId;
  const organisation = location.state.organisation;
  const transaction = location.state.transaction;
  const paymentType = location.state.paymentType;
  const systemSettings = location.state.systemSettings;
  const amount = stringToCents(transaction.amount);
  const invoiceId = location.state.invoiceId as string | undefined;
  const isAmountPrefilled = (location.state.isAmountPrefilled ||
    false) as boolean;
  const nabyCustomerId = location.state.nabyCustomerId as string | undefined;

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Pay - safe',
      firebase_screen_class: 'Pay - safe',
    });

    const createPaymentRequest = async () => {
      try {
        if (stripe) {
          const pr = stripe.paymentRequest({
            country: 'GB',
            currency: 'gbp',
            total: { label: 'NabyPay', amount },
            requestPayerName: true,
            requestPayerEmail: true,
          });

          const result = await pr.canMakePayment();
          if (result) {
            setPaymentRequest(pr);
          }
        }
      } catch (error) {
        alert(error);
        SentryIO.captureException(error, {
          extra: {
            prompt: 'Providers. Payment request failed',
          },
        });
      } finally {
        setIsLoading(false);
      }
    };

    createPaymentRequest();
  }, []);

  const onClick = async () => {
    const params = {
      userId,
      organisation,
      transaction,
      paymentType,
      systemSettings,
      invoiceId,
      isAmountPrefilled,
      nabyCustomerId,
    };

    console.log('ProvidersScreen / Params', params);

    if (paymentRequest) {
      dispatch({
        type: ActionTypes.setState,
        state: { paymentRequest: paymentRequest },
      });
    }
    history.push('/pay/method', params);
  };

  return <ProvidersContent isLoading={isLoading} onClick={onClick} />;
});

export default ProvidersScreen;
