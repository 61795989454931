import { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { logEvent } from 'firebase/analytics';

import '../../App.scss';
import { CheckoutForm } from '../../components/details/CheckoutForm';
import { analytics } from '../../services/FirebaseService';
import { Customer } from '../../types/customerTypes';

interface Props {
  match: any;
  location: any;
}

const EnterPaymentDetailsScreen: FC<Props> = ({ location }) => {
  const transaction = location.state.transaction;
  const organisation = location.state.organisation;
  const paymentType = location.state.paymentType;
  const userId = location.state.userId;
  const systemSettings = location.state.systemSettings;
  const invoiceId = location.state.invoiceId as string | undefined;
  const nabyCustomerId = location.state.nabyCustomerId as string | undefined;
  const isAmountPrefilled = location.state.isAmountPrefilled || false;
  const nabyCustomerData = location.state.nabyCustomerData as
    | Customer
    | undefined;

  const history = useHistory();

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Pay - enter card',
      firebase_screen_class: 'Pay - enter card',
    });
  }, []);

  const showSuccess = (transaction: any) =>
    history.push('/pay/success', { organisation, transaction });
  const showFailure = (error: string) =>
    history.push('/pay/failure', {
      userId,
      organisation,
      transaction,
      error,
      systemSettings,
      invoiceId,
      nabyCustomerId,
      paymentType,
      isAmountPrefilled,
      nabyCustomerData,
    });

  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          location={location}
          stripe={stripe}
          elements={elements}
          paymentSuccess={showSuccess}
          paymentFailed={showFailure}
          isAmountPrefilled={isAmountPrefilled}
          paymentType={paymentType}
          transaction={transaction}
          organisation={organisation}
          userId={userId}
          systemSettings={systemSettings}
          invoiceId={invoiceId}
          nabyCustomerId={nabyCustomerId}
          nabyCustomerData={nabyCustomerData}
        />
      )}
    </ElementsConsumer>
  );
};

export default EnterPaymentDetailsScreen;
