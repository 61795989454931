import { useEffect, useState } from 'react';

import { Customer } from '../../../types/customerTypes';
import { getCustomer } from '../../../services/FirestoreService';

/**
 * Fetches customer data based on the provided Naby customer ID.
 */
export const useCustomerData = ({
  nabyCustomerId,
  location,
}: {
  nabyCustomerId?: string;
  location: {
    state: {
      nabyCustomerData?: Customer;
    };
  };
}) => {
  const [fetchingCustomerData, setFetchingCustomerData] = useState(false);
  const [nabyCustomerData, setNabyCustomerData] = useState<
    Customer | undefined
  >();

  const statedCustomerData = location.state?.nabyCustomerData as
    | Customer
    | undefined;

  useEffect(() => {
    if (!nabyCustomerId) {
      return;
    }

    if (nabyCustomerData) {
      return;
    }

    if (statedCustomerData) {
      setNabyCustomerData(statedCustomerData);
      return;
    }

    const fetchNabyCustomerData = async () => {
      try {
        setFetchingCustomerData;
        const customerData = await getCustomer(nabyCustomerId);
        setNabyCustomerData(customerData);
      } catch (error) {
        console.error('Error fetching Naby customer data:', error);
      } finally {
        setFetchingCustomerData(false);
      }
    };

    fetchNabyCustomerData();
  }, [nabyCustomerId, nabyCustomerData]);

  return {
    fetchingCustomerData,
    nabyCustomerData,
  };
};
