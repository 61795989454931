export class InvoceUtils {
  /**
   * Format invoice number.
   *
   * @function
   * @returns The formatted invoice number.
   */
  static formatInvoiceNumber = ({
    counter,
    length,
  }: {
    counter: number;
    length: number;
  }): string => {
    return counter.toString().padStart(length, '0');
  };
}
