import Lottie from 'lottie-react';
import { PaymentIntent } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';

import './styles.scss';
import Body from '../../../widgets/layout/Body';
import Container from '../../../widgets/layout/Container';
import loaderAnimation from '../../../assets/lottie/loader.json';
import { SentryIO } from '../../../services/SentryIO';
import { useQuery } from '../../../hooks';
import { get3DSecureRedirectData } from './get3DSecureRedirectData';

// TODO: provide jsdoc
export const ThreeDSecureRedirectScreen = () => {
  const history = useHistory();
  const stripe = useStripe();
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>();

  const query = useQuery();
  const paymentIntentClientSecret = query.get('payment_intent_client_secret');

  // Retrieve payment intent data using its ID
  useEffect(() => {
    if (!stripe || !paymentIntentClientSecret) {
      return;
    }

    (async () => {
      try {
        const paymentIntentResult = await stripe.retrievePaymentIntent(
          paymentIntentClientSecret,
        );

        if (paymentIntentResult.error) {
          throw new Error(paymentIntentResult.error.message);
        }

        setPaymentIntent(paymentIntentResult.paymentIntent);
      } catch (error) {
        SentryIO.captureException(error, {
          extra: {
            context: `Failed to retrieve payment intent`,
          },
        });

        // Navigate to the error screen
        const threeDSecureRedirectData = get3DSecureRedirectData();

        if (!threeDSecureRedirectData) {
          return;
        }
        console.log('threeDSecureRedirectData 2', threeDSecureRedirectData);
        // Navigate suer to the failure screen
        history.replace('/pay/failure', {
          userId: threeDSecureRedirectData.userId,
          organisation: threeDSecureRedirectData.organisation,
          transaction: threeDSecureRedirectData.transaction,
          error: 'Failed to retrieve payment data',
          systemSettings: threeDSecureRedirectData.systemSettings,
          invoiceId: threeDSecureRedirectData.invoiceId,
          paymentType: threeDSecureRedirectData.paymentType,
          isAmountPrefilled: threeDSecureRedirectData.isAmountPrefilled,
          nabyCustomerId: threeDSecureRedirectData.nabyCustomerId,
          nabyCustomerData: threeDSecureRedirectData.nabyCustomerData,
        });
      }
    })();
  }, [stripe, paymentIntentClientSecret]);

  // Navigate user to success or failure screen depending on the payment intent
  useEffect(() => {
    if (!paymentIntent) {
      return;
    }

    const threeDSecureRedirectData = get3DSecureRedirectData();

    if (!threeDSecureRedirectData) {
      return;
    }

    // Navigate user to the success screen
    if (paymentIntent.status === 'succeeded') {
      history.replace('/pay/success', {
        organisation: threeDSecureRedirectData.organisation,
        transaction: threeDSecureRedirectData.transaction,
      });
      return;
    }
    console.log('threeDSecureRedirectData 1', threeDSecureRedirectData);

    // Navigate user to the failure screen
    history.replace('/pay/failure', {
      userId: threeDSecureRedirectData.userId,
      organisation: threeDSecureRedirectData.organisation,
      transaction: threeDSecureRedirectData.transaction,
      error:
        paymentIntent.last_payment_error?.message || 'Something went wrong',
      systemSettings: threeDSecureRedirectData.systemSettings,
      invoiceId: threeDSecureRedirectData.invoiceId,
      paymentType: threeDSecureRedirectData.paymentType,
      isAmountPrefilled: threeDSecureRedirectData.isAmountPrefilled,
      nabyCustomerId: threeDSecureRedirectData.nabyCustomerId,
      nabyCustomerData: threeDSecureRedirectData.nabyCustomerData,
    });
  }, [paymentIntent]);

  return (
    <Container>
      <Body className="threeD-secure-redirect__body">
        <Lottie
          animationData={loaderAnimation}
          className="threeD-secure-redirect__loader"
          loop
        />
      </Body>
    </Container>
  );
};
