import { memo, useEffect } from 'react';
import { API } from '../../api/ApiClient';

import './PaymentMethodContent.scss';
import ApplePayment from './ApplePayment';
import Body from '../../widgets/layout/Body';
import Button from '../common/Button';
import Container from '../../widgets/layout/Container';
import Header from '../common/Header';
import { NabyOrganization } from '../../models/NabyAccount';
import { ReactComponent as ClockFastIcon } from '../../assets/icons/ic-clock-fast-grey.svg';
import { TPaymentType } from '../../models/UserMetadata';
import { stringToCents } from '../../utils/Money';
import { usePaymentMethods } from '../../hooks';
import { Customer } from '../../types/customerTypes';

type Props = {
  isLoading: boolean;
  paymentFields: {
    userId: string;
    organisation: NabyOrganization;
    transaction: any;
  };
  bankTransferClick: () => void;
  cardClick: () => void;
  paymentRequestParam: any;
  isAmountPrefilled?: boolean;
  paymentType?: TPaymentType;
  invoiceId?: string;
  nabyCustomerId?: string;
  nabyCustomerData?: Customer;
};

const PaymentMethodContent = memo(
  ({
    isLoading,
    paymentFields,
    bankTransferClick,
    cardClick,
    paymentRequestParam,
    paymentType,
    isAmountPrefilled,
    invoiceId,
    nabyCustomerData,
    nabyCustomerId,
  }: Props) => {
    const { userId, organisation, transaction } = paymentFields;

    const maxAmount = 1000000;
    const { stripeBankEnabled, stripeCardEnabled } = usePaymentMethods(
      organisation,
      stringToCents(transaction.amount) > maxAmount,
    );

    /**
     * Hide the Open-Banking option until user fixes orgname
     */
    const isStatementDescritorValid = /^[a-zA-Z0-9 ]+$/.test(
      organisation.orgname,
    );

    useEffect(() => {
      if (!stripeCardEnabled && !stripeBankEnabled) {
        API.sendNoPaymentMethodsMessage(userId);
      }
    }, [stripeCardEnabled, stripeBankEnabled]);

    if (stripeBankEnabled || stripeCardEnabled) {
      return (
        <Container>
          <Body>
            <Header>Pay with your...</Header>
            <>
              {stripeBankEnabled && isStatementDescritorValid && (
                <div
                  className="payment-method__bank-account"
                  style={isLoading ? { paddingTop: '9px !important' } : {}}>
                  <div className="payment-method__bank-account-background" />
                  {isLoading ? (
                    <div className="payment-method__bank-account-spinner">
                      <div id="loading-spinner" />
                    </div>
                  ) : (
                    <Button
                      btnType="primary"
                      label="Bank Account"
                      onClick={bankTransferClick}
                      style={{ marginTop: 0 }}
                    />
                  )}
                  <div className="payment-method__back-account-description">
                    <div className="payment-method__back-account-description-row">
                      <ClockFastIcon className="payment-method__clock-fast-icon" />
                      <p className="payment-method__back-account-description-heading">
                        Fast way to pay
                      </p>
                    </div>

                    <p className="payment-method__back-account-description-text">
                      Pay with a bank account without entering your account
                      details.
                    </p>
                  </div>
                </div>
              )}

              {stripeCardEnabled && (
                <>
                  <Button
                    disabled={isLoading}
                    label="Credit / Debit Card"
                    onClick={cardClick}
                  />
                  <ApplePayment
                    disabled={isLoading}
                    paymentFields={paymentFields}
                    paymentRequestParam={paymentRequestParam}
                    paymentType={paymentType}
                    isAmountPrefilled={isAmountPrefilled}
                    invoiceId={invoiceId}
                    nabyCustomerId={nabyCustomerId}
                    nabyCustomerData={nabyCustomerData}
                  />
                </>
              )}
            </>
          </Body>
        </Container>
      );
    }

    return null;
  },
);

export default PaymentMethodContent;
