import { httpsCallable } from 'firebase/functions';
import { PaymentIntent } from '@stripe/stripe-js';

import { RequiredPaymentIntentFields } from '../services/stripe/models';
import { functions } from '../services/FirebaseService';
import { TPaymentType } from '../models/UserMetadata';

export default class StripeApi {
  static createPaymentIntent = (
    paymentIntentFields: RequiredPaymentIntentFields,
  ) => {
    const invoiceId = paymentIntentFields.invoiceId;
    const nabyCustomerId = paymentIntentFields.nabyCustomerId;

    const params: Record<string, any> = {
      userId: paymentIntentFields.userId,
      organisationId: paymentIntentFields.organisationId,
      amount: paymentIntentFields.amount,
      currency: paymentIntentFields.currency,
      refNum: paymentIntentFields.refNum,
      isOpenBanking: paymentIntentFields.isOpenBanking,
      isAmountPrefilled: paymentIntentFields.isAmountPrefilled,
      paymentType: paymentIntentFields.paymentType,
    };

    if (invoiceId) {
      params.invoiceId = invoiceId;
    }

    if (nabyCustomerId) {
      params.nabyCustomerId = nabyCustomerId;
    }

    console.log('StripeApi / createPaymentIntent', params);

    return httpsCallable<any, { id: string; clientSecret: string }>(
      functions,
      'createStripePaymentIntent',
    )(params);
  };

  static processPaymentIntent = (
    paymentIntentId: string,
    paymentMethodId: string,
    country: string,
    amountCents: number,
  ) =>
    httpsCallable<any, PaymentIntent>(
      functions,
      'processPaymentIntent',
    )({ paymentIntentId, paymentMethodId, country, amountCents });

  static proccessPayment = (paymentFields: {
    userId: string;
    organisationId: string;
    amount: number;
    currency: string;
    refNum: string;
    date: number;
    country: string;
    paymentMethodId: string;
    isCardPremium: boolean;
    isAmountPrefilled?: boolean;
    paymentType?: TPaymentType;
    customerId?: string;
    invoiceId?: string;
    nabyCustomerId?: string;
  }) =>
    httpsCallable<any, PaymentIntent>(
      functions,
      'stripeProcessPayment',
    )(paymentFields);

  /**
   * Initiates the card payment process by sending payment details to a cloud function
   * which handles the creation and confirmation of a payment intent.
   */
  static handleCardPayment = (paymentFields: {
    email: string;
    fullName?: string;
    phone?: string;
    address?: {
      line1: string;
      line2: string | null;
      city: string;
      state: string;
      postal_code: string;
      country: string;
    };
    organizationId: string;
    amount: number;
    country: string;
    isCardPremium: boolean;
    isAmountPrefilled: boolean;
    date: number;
    userId: string;
    refNum: string;
    paymentType?: TPaymentType;
    currency: string;
    createdPaymentMethodId: string;
    invoiceId?: string;
    nabyCustomerId?: string;
  }) =>
    httpsCallable<any, PaymentIntent & { error?: boolean; message?: string }>(
      functions,
      'handleCardPayment',
    )(paymentFields);
}
